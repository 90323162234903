"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var react_router_1 = require("react-router");
var hooks_1 = require("utils/hooks");
var ClientSingleInvoice = function () {
    var match = (0, react_router_1.useRouteMatch)('/client-invoice/:invId');
    var cId = (0, hooks_1.useQParam)('cId');
    (0, react_1.useEffect)(function () {
        var _a;
        window.location.replace("/client-agreement?cId=".concat(cId, "#INV-").concat((_a = match === null || match === void 0 ? void 0 : match.params) === null || _a === void 0 ? void 0 : _a.invId));
    });
    return null;
};
exports.default = ClientSingleInvoice;
