"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseUSDate = exports.parseCorrectSlashedDate = exports.getTimezoneMidnight = exports.getTimeZoneOffSet = exports.getDateNice = exports.getDateRange = exports.getISODate = exports.toDate = exports.makeRawTimeRange = exports.getMonthName = exports.parseTimeRange = exports.stringifyDuration = exports.MS_PER_DAY = void 0;
var date_fns_1 = require("date-fns");
var MS_PER_MIN = 60 * 1000;
exports.MS_PER_DAY = 24 * 60 * MS_PER_MIN;
var stringifyDuration = function (timeFrame) { return timeFrame
    ? Object.entries(timeFrame).map(function (_a) {
        var _b = __read(_a, 2), period = _b[0], count = _b[1];
        return period === 'allowContinue' ? 'with allowContinue' : "".concat(count, " ").concat(period);
    }).join()
    : "".concat(timeFrame); };
exports.stringifyDuration = stringifyDuration;
var parseTimeRange = function (timeRange) { return ({
    start: timeRange.start ? new Date(timeRange.start) : undefined,
    end: timeRange.end ? new Date(timeRange.end) : undefined,
}); };
exports.parseTimeRange = parseTimeRange;
var getMonthName = function (date) { return date.toLocaleString('default', { month: 'long' }); };
exports.getMonthName = getMonthName;
var makeRawTimeRange = function (start, end) {
    if (end === void 0) { end = new Date(); }
    return JSON.parse(JSON.stringify({ start: start, end: end }));
};
exports.makeRawTimeRange = makeRawTimeRange;
function toDate(date) {
    return !date || date instanceof Date ? date : date.toDate();
}
exports.toDate = toDate;
function getISODate(date) {
    return date ? toDate(date).toJSON().split('T')[0] : undefined;
}
exports.getISODate = getISODate;
var getDateRange = function (startDateOrTS, endDateOrTS, isShort) {
    var _a = __read([startDateOrTS, endDateOrTS].map(toDate), 2), startDate = _a[0], endDate = _a[1];
    if (startDate.getFullYear() === endDate.getFullYear()) {
        return "".concat((0, date_fns_1.format)(startDate, 'dd MMM'), " - ").concat((0, date_fns_1.format)(endDate, "dd MMM ".concat(isShort ? '' : 'yy', "yy")));
    }
    return [startDate, endDate].map(function (date) { return (0, date_fns_1.format)(date, "dd MMM ".concat(isShort ? '' : 'yy', "yy")); }).join(' - ');
};
exports.getDateRange = getDateRange;
var getDateNice = function (date, isShort, fallback) {
    if (fallback === void 0) { fallback = '-'; }
    return date ? (0, date_fns_1.format)(toDate(date), "dd MMM ".concat(isShort ? '' : 'yy', "yy")) : fallback;
};
exports.getDateNice = getDateNice;
var getTimeZoneOffSet = function (timeZone) {
    var _a;
    return (_a = new Date().toLocaleString('sv-SE', { timeZone: timeZone, timeZoneName: 'longOffset' })
        .match(/GMT([+-]\d+:\d+)/)) === null || _a === void 0 ? void 0 : _a[1].replace(/:/, '');
};
exports.getTimeZoneOffSet = getTimeZoneOffSet;
var getTimezoneMidnight = function (dateStr, timeZone) { return new Date("".concat(dateStr, "T00:00:00").concat((0, exports.getTimeZoneOffSet)(timeZone))); };
exports.getTimezoneMidnight = getTimezoneMidnight;
var parseCorrectSlashedDate = function (dateStr) {
    if (!dateStr) {
        return undefined;
    }
    var parts = dateStr.split('/');
    return new Date(+parts[2], +parts[1] - 1, +parts[0]);
};
exports.parseCorrectSlashedDate = parseCorrectSlashedDate;
var parseUSDate = function (dateStr) {
    if (!dateStr) {
        return undefined;
    }
    var parts = dateStr.split('/');
    return new Date(+parts[2], +parts[0] - 1, +parts[1]);
};
exports.parseUSDate = parseUSDate;
