"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.userMinFields = exports.companyForAdminAggregateFields = exports.companyForAccountantFields = exports.companyForAdminFields = exports.companyForAdminTempFlags = exports.companyTempFlags = exports.companyPrivateFields = void 0;
exports.companyPrivateFields = [
    'name', 'plan', 'accountingFirmId', 'lastSync', 'baseCurrency', 'createdAt', 'creationFlow', 'isTest', 'timeZone', 'hasRecharglyAccess'
];
exports.companyTempFlags = ['isCalculatingRecommendations', 'isAggregatingServiceStats',
    'isMappingTransactions', 'isGeneratingContacts', 'isQueuedToWriteServiceStats', 'isResyncing', 'isCheckingUncuratedServices'];
exports.companyForAdminTempFlags = exports.companyTempFlags.map(function (field) { return "".concat(field, "StartedAt"); });
exports.companyForAdminFields = __spreadArray(__spreadArray(['name', 'logoUrl', 'serviceStatsCount', 'userCount', 'plan', 'planFrequency', 'creationFlow',
    'employeeCount', 'accountingFirmId', 'ownerId', 'accountantCreatorId', 'baseCurrency', 'lastSync', 'createdAt', 'isTest',
    'isAccountingFirm', 'isRechargeClient', 'hasRecharglyAccess', 'timeZone'], __read(exports.companyTempFlags), false), __read(exports.companyForAdminTempFlags), false);
exports.companyForAccountantFields = ['name', 'logoUrl', 'baseCurrency', 'serviceStatsCount',
    'plan', 'employeeCount', 'userCount', 'createdAt', 'accountingFirmId', 'ownerId', 'accountantCreatorId', 'isRechargeClient',
    'ownerData', 'accountantCreatorData', 'connectionsData', 'connectionIds', 'totalLTMSaasSpend', 'totalLTMSaasSpendInFx'
];
exports.companyForAdminAggregateFields = ['totalLTMSaasSpend', 'total13to24MonthsSaasSpend', 'total25to36MonthsSaasSpend',
    'totalBeforeMonth36SaasSpend', 'totalSaasSpend'];
exports.userMinFields = ['displayName', 'email'];
