"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTaxRateObj = exports.getCancellationType = exports.getCustomerExtendedAgreementStatus = exports.getCustomerAgreementUrl = exports.getRechargeCustomerHasDiscount = exports.getRechargeRechargerStatus = exports.getRechargeDescription = exports.getLineItems = exports.getInvoiceCode = void 0;
var numbers_1 = require("./numbers");
var utils_1 = require("./utils");
var getInvoiceCode = function (inv) { return inv.invoiceCodeOverride || inv.inPaymentPlatformNumber || "".concat(inv.invoicePrefix, "-").concat((0, numbers_1.zeroPadding)(inv.invoiceNr, 4)); };
exports.getInvoiceCode = getInvoiceCode;
var getLineItems = function (inv) { var _a; return (_a = inv.clientLineItems) !== null && _a !== void 0 ? _a : inv.lineItems; };
exports.getLineItems = getLineItems;
var getRechargeDescription = function (recharge, rechargedOrganization, hasMultipleOrgs, hideDetails) {
    var _a;
    return [
        [
            [
                recharge.rechVendorId && !((_a = recharge.plan) === null || _a === void 0 ? void 0 : _a.startsWith(recharge.rechVendorId)) ? recharge.rechVendorId : '',
                recharge.plan
            ].filter(utils_1.notFalsy).join(' - '),
            recharge.unitCount && "".concat(recharge.unitCount, " ").concat(recharge.unitText),
            !hideDetails && recharge.details && "".concat(recharge.details)
        ].filter(utils_1.notFalsy).join(' · '),
        hasMultipleOrgs && " (".concat(rechargedOrganization.name, ")"),
    ].filter(utils_1.notFalsy).join('');
};
exports.getRechargeDescription = getRechargeDescription;
var getRechargeRechargerStatus = function (enabledVendors) { return function (recharge, rechargedCustomer) {
    if (recharge.isExcluded) {
        return 'excluded';
    }
    if (recharge.isFailed) {
        return 'failed';
    }
    if (recharge.status === 'new') {
        var vendor = recharge.rechVendorId;
        return (rechargedCustomer === null || rechargedCustomer === void 0 ? void 0 : rechargedCustomer.isRechargeEnabled) && (!vendor || (enabledVendors === null || enabledVendors === void 0 ? void 0 : enabledVendors.includes(vendor))) ? 'nextInvoice' : 'new';
    }
    return recharge.status;
}; };
exports.getRechargeRechargerStatus = getRechargeRechargerStatus;
var getRechargeCustomerHasDiscount = function (rechargedCustomer, rechargerCompany, vendors) { return rechargedCustomer.accFirmDiscountSplit !== undefined
    ? rechargedCustomer.accFirmDiscountSplit < 0.995
    : vendors.some(function (vendor) { var _a, _b; return ((_b = (_a = rechargerCompany.discountSplitMap) === null || _a === void 0 ? void 0 : _a[vendor]) !== null && _b !== void 0 ? _b : 0) < 0.995; }); };
exports.getRechargeCustomerHasDiscount = getRechargeCustomerHasDiscount;
var getCustomerAgreementUrl = function (rechargedCustomer, source, origin) {
    if (origin === void 0) { origin = 'https://app.rechargly.com'; }
    return "".concat(origin, "/client-agreement?cId=").concat(rechargedCustomer.publicId).concat(source ? "&s=".concat(source) : '');
};
exports.getCustomerAgreementUrl = getCustomerAgreementUrl;
var getCustomerExtendedAgreementStatus = function (rechargedCustomer) {
    var _a, _b, _c;
    if (!rechargedCustomer.agreementStatus) {
        return 'scheduled';
    }
    if (rechargedCustomer.agreementStatus === 'sent') {
        if ((_a = rechargedCustomer.agreementOpened) === null || _a === void 0 ? void 0 : _a.fromEmail) {
            if ((_b = rechargedCustomer.agreementOpened) === null || _b === void 0 ? void 0 : _b.fromCopyLink) {
                return 'opened_by_all';
            }
            return 'opened_by_email';
        }
        if ((_c = rechargedCustomer.agreementOpened) === null || _c === void 0 ? void 0 : _c.fromCopyLink) {
            return 'opened_by_copy_link';
        }
    }
    if (rechargedCustomer.migratedAgreement && rechargedCustomer.agreementStatus === 'accepted') {
        return 'migrated';
    }
    return rechargedCustomer.agreementStatus;
};
exports.getCustomerExtendedAgreementStatus = getCustomerExtendedAgreementStatus;
var getCancellationType = function (rechargedCustomer) {
    return rechargedCustomer.stripePaymentMethod ? 'Cancel'
        : rechargedCustomer.agreementSentOn ? 'Retract'
            : 'Disable';
};
exports.getCancellationType = getCancellationType;
var TAX_RATE_BY_COUNTRY = {
    AU: { rate: 0.1, name: 'GST' },
    NZ: { rate: 0.15, name: 'GST' },
    GB: { rate: 0.2, name: 'VAT' },
};
var getTaxRateObj = function (accFirm, rechargedCustomer) {
    var _a, _b;
    var taxObj = (_b = TAX_RATE_BY_COUNTRY[(_a = accFirm.countryCode) !== null && _a !== void 0 ? _a : 'AU']) !== null && _b !== void 0 ? _b : TAX_RATE_BY_COUNTRY.AU;
    return (rechargedCustomer === null || rechargedCustomer === void 0 ? void 0 : rechargedCustomer.isTaxExcluded) ? { rate: 0, name: taxObj.name } : taxObj;
};
exports.getTaxRateObj = getTaxRateObj;
