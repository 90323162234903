"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FILE_UPLOADER_ERROR_MAPPER = exports.VENDOR_LOGOS = exports.VENDORS_LIST = exports.RECHARGES_MAIL_STATUS_MAPPER = exports.EMAIL_PROVIDERS_LOGO = exports.EMAIL_PROVIDERS = exports.RECHARGE_STATUS_MAPPER = exports.BILLING_STATUS_ORDER = exports.MATCH_LEVEL_MAPPER = exports.CONNECTIONS_TABS = exports.AUTO_RECHARGING_TABS = exports.CRAWLED_CUSTOMERS_MIGRATION_TABS = exports.CUSTOMERS_MIGRATION_TABS = exports.MAP_CUSTOMERS_TABS = exports.AGREEMENT_STATUS_MAPPER = void 0;
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var faCheckCircle_1 = require("@fortawesome/pro-light-svg-icons/faCheckCircle");
var faCircleArrowRight_1 = require("@fortawesome/pro-light-svg-icons/faCircleArrowRight");
var faCirclesOverlap_1 = require("@fortawesome/pro-light-svg-icons/faCirclesOverlap");
var faCircleXmark_1 = require("@fortawesome/pro-light-svg-icons/faCircleXmark");
var faEnvelopeOpen_1 = require("@fortawesome/pro-light-svg-icons/faEnvelopeOpen");
var faFileInvoiceDollar_1 = require("@fortawesome/pro-light-svg-icons/faFileInvoiceDollar");
var faBallotCheck_1 = require("@fortawesome/pro-regular-svg-icons/faBallotCheck");
var faCircleCheck_1 = require("@fortawesome/pro-regular-svg-icons/faCircleCheck");
var faCircleXmark_2 = require("@fortawesome/pro-regular-svg-icons/faCircleXmark");
var faPlusCircle_1 = require("@fortawesome/pro-regular-svg-icons/faPlusCircle");
var constants_1 = require("common/constants");
var constants_2 = require("containers/AssistedOnboarding/WelcomeFlow/constants");
exports.AGREEMENT_STATUS_MAPPER = {
    opened_by_email: {
        text: 'Opened',
        iconColor: constants_1.COLORS.azzurro.A400,
        icon: faEnvelopeOpen_1.faEnvelopeOpen,
        tooltip: { description: 'MD:The agreement link sent by **email** was opened by someone.' },
    },
    opened_by_copy_link: {
        text: 'Opened (by link)',
        iconColor: constants_1.COLORS.azzurro.A400,
        icon: faEnvelopeOpen_1.faEnvelopeOpen,
        tooltip: { description: 'MD:The agreement shared by **Copy link** was opened by someone. The **email** link wasn’t opened.' },
    },
    opened_by_all: {
        text: 'Opened',
        iconColor: constants_1.COLORS.azzurro.A400,
        icon: faEnvelopeOpen_1.faEnvelopeOpen,
        tooltip: { description: 'MD:The agreement link was opened both from **email** and the **Copy link** share button.' },
    },
    scheduled: {
        text: 'Scheduled for sending', iconColor: constants_1.COLORS.azzurro.A400, icon: faFileInvoiceDollar_1.faFileInvoiceDollar,
    },
    sent: {
        text: 'Sent', iconColor: constants_1.COLORS.azzurro.A400, icon: faBallotCheck_1.faBallotCheck,
    },
    accepted: {
        text: 'Accepted', iconColor: constants_1.COLORS.green.A500, icon: faCheckCircle_1.faCheckCircle,
    },
    cancelled: {
        text: 'Cancelled', iconColor: constants_1.COLORS.neutral.A500, icon: faCircleXmark_1.faCircleXmark,
    },
    migrated: {
        text: 'Migrated', iconColor: constants_1.COLORS.green.A500, icon: faCircleArrowRight_1.faCircleArrowRight,
    },
};
exports.MAP_CUSTOMERS_TABS = [
    { label: 'For Review', href: '/recharges/mapping/for-review' },
    { label: 'Confirmed', href: '/recharges/mapping/confirmed' },
    { label: 'Skipped', href: '/recharges/mapping/skipped' },
    { label: 'All', href: '/recharges/mapping/all' },
];
exports.CUSTOMERS_MIGRATION_TABS = [
    { label: 'For Review', href: '/recharges/migration/for-review' },
    { label: 'Confirmed', href: '/recharges/migration/confirmed' },
    { label: 'All', href: '/recharges/migration/all' },
];
exports.CRAWLED_CUSTOMERS_MIGRATION_TABS = [
    { label: 'For Review', href: '/recharges/non-au-migration/for-review' },
    { label: 'Confirmed', href: '/recharges/non-au-migration/confirmed' },
    { label: 'All', href: '/recharges/non-au-migration/all' },
];
exports.AUTO_RECHARGING_TABS = [
    { label: 'Customers', href: '/recharges/billing/customers' },
    // { label: 'New', href: '/recharges/billing/new' },
    // { label: 'Charges', href: '/recharges/billing/charges' },
    // { label: 'Recharging', href: '/recharges/billing/recharging' },
];
exports.CONNECTIONS_TABS = [
    { label: 'Xero Connections', href: '/recharges/connections/xero' },
];
exports.MATCH_LEVEL_MAPPER = {
    exact: {
        text: 'Exact',
        iconColor: '#30BC86',
        icon: faCircleCheck_1.faCircleCheck,
    },
    strong: {
        text: 'Strong',
        iconColor: constants_1.COLORS.riverstone,
        icon: faCircleCheck_1.faCircleCheck,
    },
    weak: {
        text: 'Similar',
        iconColor: '#9CA9B0',
        icon: faCircleCheck_1.faCircleCheck,
    },
    notFound: {
        text: 'Not Found',
        iconColor: '#EC7069',
        icon: faCircleXmark_2.faCircleXmark,
    },
    multiple: {
        text: 'Multiple',
        iconColor: '#23AFEE',
        icon: faCirclesOverlap_1.faCirclesOverlap,
    },
};
exports.BILLING_STATUS_ORDER = [
    'new', 'scheduled', 'sent', 'agreementAccepted', 'processingFirstPayment', 'recharging', 'paymentAttemptFailed', 'cancelled',
    'inactive',
];
exports.RECHARGE_STATUS_MAPPER = {
    new: {
        text: 'New customer',
        iconColor: '#9CA9B0',
        icon: faPlusCircle_1.faPlusCircle,
    },
    recharging: {
        text: 'Recharging',
        iconColor: '#02AD6B',
        img: '/active-recharging.svg',
    },
    processingFirstPayment: {
        text: 'Processing first payment',
        iconColor: '#02AD6B',
        icon: pro_light_svg_icons_1.faCircleDollarToSlot,
    },
    paymentAttemptFailed: {
        text: 'Payment attempt failed',
        iconColor: '#EC7069',
        icon: faCircleXmark_1.faCircleXmark,
    },
    sent: __assign(__assign({}, exports.AGREEMENT_STATUS_MAPPER.sent), { text: 'Agreement sent' }),
    cancelled: __assign({}, exports.AGREEMENT_STATUS_MAPPER.cancelled),
    scheduled: __assign(__assign({}, exports.AGREEMENT_STATUS_MAPPER.scheduled), { text: 'Scheduled' }),
    agreementAccepted: __assign(__assign({}, exports.AGREEMENT_STATUS_MAPPER.accepted), { text: 'Agreement accepted' }),
    inactive: {
        text: 'Inactive',
        iconColor: '#9CA9B0',
        icon: faCircleXmark_1.faCircleXmark,
    },
};
exports.EMAIL_PROVIDERS = ['Gmail', 'Outlook', 'Other'];
exports.EMAIL_PROVIDERS_LOGO = {
    Gmail: {
        label: 'Gmail/Workspace',
        logo: '/Gmail.png',
    },
    Outlook: {
        label: 'Microsoft Outlook',
        logo: '/Outlook.png',
    },
    Other: {
        label: 'Other email providers',
        icon: pro_light_svg_icons_1.faEnvelopesBulk,
    },
};
exports.RECHARGES_MAIL_STATUS_MAPPER = {
    pendingReception: {
        status: 'pending',
        text: '(Pending email reception)',
    },
    processed: {
        status: 'success',
        text: '(Received correctly)',
    },
    incorrectEmail: {
        status: 'error',
        text: '(Incorrect email, try again)',
    },
    errorFetchingData: {
        status: 'error',
        text: '(Error fetching data, contact support)',
    },
};
exports.VENDORS_LIST = ['Xero', 'QuickBooks', 'A2X', 'Gusto', 'Employment Hero', 'Dext', 'Lightyear', 'ApprovalMax',
    'Docusign', 'Bill.com', 'AccountKit', 'NetSuite', 'Other'];
exports.VENDOR_LOGOS = {
    Xero: {
        label: 'Xero',
        logo: constants_2.ACC_PLATFORMS_LOGO.Xero,
    },
    QuickBooks: {
        label: 'QuickBooks',
        logo: constants_2.ACC_PLATFORMS_LOGO.QuickBooks,
    },
    NetSuite: {
        label: 'NetSuite',
        logo: constants_2.ACC_PLATFORMS_LOGO.NetSuite,
    },
    Other: {
        label: 'Other or not sure...',
        icon: pro_light_svg_icons_1.faQuestionCircle,
        iconColor: '#200384',
    },
};
exports.FILE_UPLOADER_ERROR_MAPPER = {
    ALREADY_EXTRACTED: 'File has been uploaded before.',
};
