"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EXAMPLE_CSVS = exports.validateCsvFile = void 0;
var end2end_1 = require("@hudled/end2end");
var interfaces_1 = require("@hudled/interfaces");
var sync_1 = require("csv-parse/browser/esm/sync");
var files_1 = require("utils/form/files");
var CSV_FIELDS = {
    Xero: interfaces_1.XERO_FIELDS,
    ApprovalMax: interfaces_1.APPROVAL_MAX_FIELDS,
    Dext: interfaces_1.DEXT_FIELDS,
    custom: ['organisationName', 'listPrice'],
};
var validateCsvFile = function (vendor, rechargeImport) { return function (csvFile) { return __awaiter(void 0, void 0, void 0, function () {
    var csvString, allRows, headers, rows, rowIdxOptReq, rowIdxTypes, linesToRm, uploadReqs;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(csvFile === null || csvFile === void 0 ? void 0 : csvFile[0])) {
                    return [2 /*return*/, { error: 'Please provide a file of the right format' }];
                }
                return [4 /*yield*/, (0, files_1.readFile)(csvFile[0])];
            case 1:
                csvString = _a.sent();
                allRows = csvString.split('\n').map(function (r) { return r.trim(); }).filter(Boolean);
                headers = allRows[0].split(',');
                if (CSV_FIELDS[vendor] && !CSV_FIELDS[vendor].every(function (field) { return headers.includes(field); })) {
                    return [2 /*return*/, {
                            csvString: csvString,
                            error: vendor !== 'custom'
                                ? 'MD:Make sure that the CSV is the original one from the vendor. '
                                    + "Check this <a href=\"/".concat(exports.EXAMPLE_CSVS[vendor], "\" target=\"_blank\" rel=\"noreferrer\">example</a>.")
                                : 'MD:Missing fields in custom CSV. '
                                    + "Check this <a href=\"/".concat(exports.EXAMPLE_CSVS[vendor], "\" target=\"_blank\" rel=\"noreferrer\">example</a>."),
                        }];
                }
                if (vendor === 'custom') {
                    rows = (0, sync_1.parse)(csvString).slice(1);
                    rowIdxOptReq = rows.findIndex(function (row) { return row.every(function (el) { return el.match(/REQUIRED|OPTIONAL/); }); }) + 1;
                    rowIdxTypes = rows.findIndex(function (row) { return row.every(function (el) { return el.match(/String|YYYY-MM-DD|Number/); }); }) + 1;
                    if (rowIdxOptReq || rowIdxTypes) {
                        linesToRm = [rowIdxOptReq, rowIdxTypes].filter(Boolean);
                        return [2 /*return*/, {
                                csvString: csvString,
                                error: "Please remove ".concat((0, end2end_1.pluralize)(linesToRm.length, 'line'), " number ").concat(linesToRm.join(' and ')),
                            }];
                    }
                }
                uploadReqs = rechargeImport === null || rechargeImport === void 0 ? void 0 : rechargeImport.uploadRequirements;
                if ((uploadReqs === null || uploadReqs === void 0 ? void 0 : uploadReqs.chargesCount) && (allRows.length - 1) !== (uploadReqs === null || uploadReqs === void 0 ? void 0 : uploadReqs.chargesCount)) {
                    return [2 /*return*/, {
                            csvString: csvString,
                            error: 'The amount of rows expected is different from the amount of rows in the CSV.',
                        }];
                }
                return [2 /*return*/, { csvString: csvString, error: null }];
        }
    });
}); }; };
exports.validateCsvFile = validateCsvFile;
exports.EXAMPLE_CSVS = {
    Xero: 'Xero_WizardBooks_INV-1234.csv',
    ApprovalMax: 'AMS-7007-24-01-14.csv',
    custom: 'CustomCSVImport.csv',
};
